import * as React from "react"
// import "../styles/materialize.css"
import Layout from "../components/layout";
import Sketch from "../components/sketch"
import sketch from "../scripts/phi"
import {graphql} from "gatsby";
import SEO from "../components/seo";

// markup

function IndexPage({data}) {
  return (
    <Layout>
      <SEO/>
      <div>
        <Sketch sketch={sketch}/>
        {/*<Sketch />*/}
        {/*<h4>{data.allMarkdownRemark.totalCount} Posts</h4>*/}
        {/*{data.allMarkdownRemark.edges.map(({node}) => (*/}
        {/*  <div key={node.id}>*/}
        {/*    <Link*/}
        {/*      to={node.fields.slug}*/}
        {/*    >*/}
        {/*      <h3>*/}
        {/*        {node.frontmatter.title}{" "}*/}
        {/*        <span>*/}
        {/*        — {node.frontmatter.date}*/}
        {/*      </span>*/}
        {/*      </h3>*/}
        {/*      <p>{node.excerpt}</p>*/}
        {/*    </Link>*/}
        {/*  </div>*/}
        {/*))}*/}

      </div>
    </Layout>

  )
}

export const query = graphql`
    query {
        allMarkdownRemark(filter: {fields: {slug: {regex: "/nfts/([A-z])/"}}},
            sort: { fields: [frontmatter___date], order: DESC }) {
            totalCount
            edges {
                node {
                    id
                    frontmatter {
                        title
                        date(formatString: "DD MMMM, YYYY")
                    }
                    fields {
                        slug
                    }
                    excerpt
                }
            }
        }
    }
`


export default IndexPage
